const React = require('react');
const ReactDOM = require('react-dom');

require('./src/prism-styles');

require('typeface-montserrat');
require('typeface-merriweather')
require('./src/normalize.css')
require('./src/style.css')
require('./src/prism-styles.js')

// Expose React and ReactDOM as globals for console playground
window.React = React;
window.ReactDOM = ReactDOM;

// A stub function is needed because gatsby won't load this file otherwise
// (https://github.com/gatsbyjs/gatsby/issues/6759)
exports.onClientEntry = () => {};
